const aboutUs = [
    {
        title: "Eccezzionale",
        description:
            "Ottima posizione. Ambiente accogliente. Personale gentile e molto professionale. Letto, doccia, colazione, arredo e quant’altro possa rendere gradevole la permanenza e’ stato tutto ai massimi livelli. Faccio volentieri i miei complimenti ai titolari per l’impronta garbata e accogliente che hanno saputo imprimere all’ambiente.",
        channel: "Booking.com",
    },
    {
        title: "Consigliatissimo",
        description:
            "Cortesia del personale. Camere moderne e accoglienti. Estremamente pulito.",
        channel: "Booking.com",
    },
    {
        title: "Ottimo Hotel",
        description:
            "Posizione ottima, vicino alla stazione dei treni ed autobus. Personale accogliente ed ambienti puliti ",
        channel: "Booking.com",
    },
    {
        title: "Ottimo soggiorno",
        description:
            "Camera pulita accogliente con tutto l’occorrente ben insonorizzata",
        channel: "Booking.com",
    },
    {
        title: "Struttura molto accogliente e personale gentilissimo.",
        description:
            "Ottima posizione vicina alla stazione ferroviaria, buonissima colazione anche senza glutine.",
        channel: "Booking.com",
    },
    {
        title: "Piacevole e da ripetere.",
        description:
            "La location strategica, il garbo, la cortesia e la disponibilità dei proprietari.",
        channel: "Booking.com",
    },
    {
        title: "Consigliatissimo",
        description:
            "Piacevolissima esperienza. Ho soggiornato due giorni con la famiglia ( 4 persone), in totale relax. Si dorme benissimo, ottima la pulizia nella stanza e comoda, anche esteticamente giudizio positivo. Proprietari e collaboratori bravissime persone, molto educate. Hotel posizionato di fronte alla stazione e a pochi passi dal centro di Santa Maria degli Angeli. Ritornerò presto!",
        channel: "TripAdvisor",
    },
    {
        title: "Settimana bellissima!!!",
        description:
            "Siamo stati in questo albergo per cinque giorni e ci siamo trovati molto bene! Il personale è accogliente e gentile. Le camere sono pulite e comode, appena ristrutturate. Il cibo è buono e abbondante (sia a colazione che a cena). Il parcheggio è vicino e libero. Comodo anche per chi viaggia in treno, perché è davanti alla stazione. Perfetto per chi vuole visitare Assisi, ma anche per chi, come noi, vuole girare anche i dintorni. Ottima qualità-prezzo. È stata una bellissima vacanza!!",
        channel: "TripAdvisor",
    },
    {
        title: "Fortemente consigliato",
        description:
            "Hotel comodissimo, di fronte alla stazione e alla fermata del bus navetta per Assisi. Pulizia perfetta, camera per 4 comoda, bagno dotato di ogni comfort e pulitissimo, molto silenzioso, colazione completa. Ci siamo trovati benissimo e lo consigliamo fortemente! Personale disponibile e sorridente, qualità rare!",
        channel: "TripAdvisor",
    },
    {
        title: "Il piacere di essere a casa!",
        description:
            "L’hotel moderno si contraddistingue per un ambiente comodo e al contempo famigliare! L’accoglienza cordiale e calorosa, il cibo fresco e abbondante, la tranquillità e la pulizia delle stanze meritano senza ombra di dubbio le cinque stelle. Consigliatissimo!",
        channel: "TripAdvisor",
    },
    {
        title: "Eccellente",
        description:
            "Io e mia amica siamo rimasti a piedi ( macchina rotta), proprietario una persona gentile e disponibile, ha accolto in orario notturno e pure senza prenotazione. GRAZIE MILLE ????",
        channel: "Google",
    },
];

export default aboutUs;
