import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import ReviewBadges from "../../components/ReviewBadges";
import Seo from "../../components/Seo";
import Separator from "../../components/Separator";
import aboutUs from "../../constants/aboutUs";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-diconoDiNoi"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function DiconoDiNoi() {
    const { t } = useTranslation();

    // Randomize reviews to preventing prioritizing some specific review
    const reviews = aboutUs.sort(() => 0.5 - Math.random());

    return (
        <Layout>
            <Seo title={t("page-diconoDiNoi:aboutUs")} />
            <Container>
                <StaticImage
                    src="../../../assets/images/recensioni.jpg"
                    alt={t("page-diconoDiNoi:aboutUs")}
                    className="rounded-4xl"
                    aspectRatio={16 / 10}
                    layout="fullWidth"
                    transformOptions={{ fit: "cover" }}
                />
                <h1 className="mt-8">{t("page-diconoDiNoi:aboutUs")}</h1>
                <div className="w-full pt-4 flex justify-center">
                    <ReviewBadges />
                </div>
                {reviews.map((review, reviewIdx) => (
                    <div key={reviewIdx}>
                        <Separator />
                        <b className="uppercase">{review.title}</b>
                        <p>{review.description}</p>
                        <small className="float-right">{review.channel}</small>
                    </div>
                ))}
            </Container>
        </Layout>
    );
}
